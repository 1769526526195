<script setup lang="ts">
import type { UiButtonProps } from "@finq/ui/components/ui/Button.vue"

const props = defineProps<UiButtonProps>()
</script>

<!-- Acts as <tr/> tag -->
<template>
  <UiButton
    v-bind="props"
    unset-all
    role="row"
    is="div"
    :class="
      cn(
        'data-[state=selected]:bg-muted min-md:min-h-16 flex w-full flex-wrap items-center border-b text-start transition-colors',
        props.class
      )
    "
  >
    <slot />
  </UiButton>
</template>
