<script setup lang="ts">
import type { HTMLAttributes } from "vue"

const props = defineProps<{
  class?: HTMLAttributes["class"]
}>()
</script>

<!-- Acts as <thead/> tag -->
<template>
  <header role="rowgroup" :class="cn('[&_tr]:border-b', props.class)">
    <slot />
  </header>
</template>
