<script setup lang="ts">
import type { HTMLAttributes } from "vue"

const props = defineProps<{
  class?: HTMLAttributes["class"]
}>()
</script>

<template>
  <div role="table" :class="cn('w-full caption-bottom text-sm', props.class)">
    <slot />
  </div>
</template>
