<script setup lang="ts">
import type { UiButtonProps } from "@finq/ui/components/ui/Button.vue"

const props = defineProps<UiButtonProps>()
</script>

<!-- Acts as <th/> tag -->
<template>
  <UiButton
    v-bind="props"
    unset-all
    role="columnheader"
    :class="
      cn(
        'relative flex h-12 items-center gap-1 px-4 text-start align-middle font-medium text-black/50 md:first:ps-5 [&:has([role=checkbox])]:pr-0',
        props.class
      )
    "
  >
    <slot />
  </UiButton>
</template>
