<script setup lang="ts">
import type { HTMLAttributes } from "vue"

const props = defineProps<{
  class?: HTMLAttributes["class"]
}>()
</script>

<!-- Acts as <tbody/> tag -->
<template>
  <div role="rowgroup" :class="cn('[&_tr:last-child]:border-0', props.class)">
    <slot />
  </div>
</template>
